<template>
  <div>
    <v-list>
      <v-list-item>
        <v-list-item-title>
          <h4>푸시 메시지 지정</h4>
        </v-list-item-title>
      </v-list-item>

      <v-row no-gutters>
        <v-card-actions>
          <v-btn text color="grey">노출 여부</v-btn>
          <v-spacer />
          <v-btn small depressed :text="status !== 'show'" :color="status !== 'show' ? null : '#ffd600'" :light="status === 'show'" @click="status = 'show'">
            바로 발송
          </v-btn>
          <v-btn
            small
            depressed
            :text="status !== 'pending'"
            :color="status !== 'pending' ? null : '#ffd600'"
            :light="status === 'pending'"
            @click="status = 'pending'"
          >
            발송 예약
          </v-btn>
          <v-btn
            v-if="id"
            small
            depressed
            :text="status !== 'keep'"
            :color="status !== 'keep' ? null : '#ffd600'"
            :light="status === 'keep'"
            @click="status = 'keep'"
          >
            발송 취소
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn text color="grey">예약 시간</v-btn>
          <v-menu v-model="dateMenu" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :disabled="status !== 'pending'"
                v-bind="attrs"
                outlined
                hide-details
                readonly
                dense
                placeholder="시작일"
                class="mx-3"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="date" :min="new Date().toISOString().substr(0, 10)" @input="dateMenu = false" />
          </v-menu>
          <v-menu v-model="timeMenu" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                :disabled="status !== 'pending'"
                v-bind="attrs"
                outlined
                hide-details
                readonly
                dense
                placeholder="시간"
                v-on="date ? on : null"
              />
            </template>
            <v-time-picker v-model="time" ampm-in-title @click:minute="timeMenu = false" />
          </v-menu>
        </v-card-actions>
      </v-row>

      <template v-if="!id">
        <v-list-item>
          <v-checkbox
            v-model="isAcceptPush"
            hide-details
            class="mt-0"
            label="마케팅 푸시 여부"
            @change="isAcceptPush ? (req.acceptPush = true) : (req.acceptPush = null)"
          />
          <span class="mt-1 ml-4" style="font-size: 13px">
            광고성 푸시인 경우 체크해주세요. (체크시 마케팅 수신동의 여부에 따라 발송됩니다.)
          </span>
        </v-list-item>

        <v-list-item class="ml-8">
          <v-btn-toggle v-model="req.acceptPush" color="#9146ff" dense>
            <v-btn class="pa-5" :value="true" :disabled="!isAcceptPush">
              동의
            </v-btn>
            <v-btn class="pa-5" :value="false" :disabled="!isAcceptPush">
              미동의
            </v-btn>
          </v-btn-toggle>
        </v-list-item>

        <v-list-item>
          <v-checkbox v-model="checked" hide-details class="mt-0" label="앱 링크 여부" />
          <span class="mt-1 ml-4" style="font-size: 13px">
            앱 특정 위치로 이동시키고 싶은 경우 체크해주세요.
          </span>
        </v-list-item>

        <v-list-item class="ml-8">
          <AppLinkInput v-model="appLink" :checked="checked" />
        </v-list-item>
      </template>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            푸시 메시지 문구
            <span class="ml-4" style="font-size: 13px">
              푸시 메시지에 노출될 문구를 입력해주세요.
            </span>
          </v-list-item-title>
          <div>
            <v-text-field
              v-model="req.message.title"
              :readonly="!!id"
              class="mt-2"
              placeholder="푸시 메시지 문구를 입력해주세요."
              outlined
              hide-details
              dense
            />
          </div>
          <v-textarea
            v-model="req.message.content"
            :readonly="!!id"
            no-resize
            class="mt-2"
            placeholder="푸시 메시지 하단 문구를 입력해주세요."
            outlined
            hide-details
            dense
          />
        </v-list-item-content>
      </v-list-item>

      <div v-if="!id">
        <v-list-item>
          <v-divider />
        </v-list-item>
        <v-list-item>
          <v-list-item-title><h4>수신자 선택</h4></v-list-item-title>
        </v-list-item>
        <v-list-item>
          전체
          <v-switch v-model="base.all" color="deep-purple accent-1" class="ml-10 mt-0" dense hide-details inset @change="changeAll" />
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              조건 선택
              <div style="margin: -16px 80px 0px; font-size: 13px">
                <div>
                  특정 조건을 선택하여 푸시를 발송하고 싶을 때 사용합니다.
                </div>
                <div>
                  추가하고 싶은 조건을 선택하여 조건추가 버튼을 누르면 조건을 추가할 수 있습니다.
                </div>
                <div>복수 선택 가능하며, 각 조건은 AND 조건이 적용됩니다.</div>
              </div>
            </v-list-item-title>
            <v-list-item-content>
              <v-card outlined class="pa-5">
                <v-row>
                  <v-col cols="3">
                    <v-btn-toggle
                      v-model="base.first"
                      color="deep-purple accent-3"
                      borderless
                      tile
                      class="d-flex flex-column"
                      @change="changeDepth(0, base.first)"
                    >
                      <v-btn v-for="(item, name) in base.ifList" :key="name" :value="name">
                        {{ name }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-divider v-if="base.first" vertical />
                  <v-col v-if="base.first === '답변평균점수'">
                    <v-rating
                      v-model="base.temp"
                      clearable
                      empty-icon="mdi-star-outline"
                      full-icon="mdi-star"
                      size="32"
                      color="deep-purple accent-1"
                      background-color="grey accent-1"
                    />
                  </v-col>
                  <v-col v-else>
                    <v-btn-toggle
                      v-model="base.second"
                      color="deep-purple accent-3"
                      borderless
                      tile
                      class="d-flex flex-column"
                      @change="changeDepth(1, base.first, base.second)"
                    >
                      <v-btn v-for="(item, name) in base.secondList" :key="name" :value="Array.isArray(base.ifList[base.first]) ? item : name">
                        {{ Array.isArray(base.ifList[base.first]) ? item : name }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-divider v-if="base.second && base.ifList[base.first][base.second]" vertical />
                  <v-col>
                    <v-btn-toggle
                      v-model="base.third"
                      color="deep-purple accent-3"
                      borderless
                      tile
                      class="d-flex flex-column"
                      @change="changeDepth(2, base.first, base.second, base.third)"
                    >
                      <v-btn v-for="(item, name) in base.thirdList" :key="name" :value="Array.isArray(base.ifList[base.first][base.second]) ? item : name">
                        {{ Array.isArray(base.ifList[base.first][base.second]) ? item : name }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-divider v-if="base.third && base.ifList[base.first][base.second][base.third]" vertical />
                  <v-col>
                    <v-btn-toggle v-model="base.fourth" color="deep-purple accent-3" borderless tile class="d-flex flex-column">
                      <v-btn v-for="name in base.fourthList" :key="name" :value="name">
                        {{ name }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn class="mx-auto my-3" color="deep-purple accent-1 font-weight-bold" depressed dark large @click="addIf">
                조건 추가
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
              <v-card outlined class="pa-5">
                <div v-for="(list, index) in ifList" :key="index" class="mt-3">
                  {{ index + 1 }}.
                  <span v-for="(item, i) in list" :key="i">{{ i > 0 && item ? '- ' : '' }} {{ item }}</span>
                  <v-btn light class="ml-3 mb-1" rounded small color="#ffd600" @click="ifList.splice(index, 1)" v-text="'삭제'" />
                </div>
              </v-card>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              그룹 선택
              <span class="ml-4" style="font-size: 13px">
                특정 유저 그룹만을 선정하여 발송하고 싶을 때 사용합니다. (복수 선택 가능)
              </span>
            </v-list-item-title>
            <v-list-item-content>
              <GroupInput v-model="group" />
              <v-btn color="#ffd600" light depressed @click="addGroupList">
                추가
              </v-btn>
              <div v-for="(item, index) in req.groupList" :key="index" class="mt-3">
                {{ index + 1 }}. {{ item.name }}
                <v-btn light class="ml-3 mb-1" rounded small color="#ffd600" @click="req.groupList.splice(index, 1)" v-text="'삭제'" />
              </div>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              유저 선택
              <div style="margin: -16px 80px 0px; font-size: 13px">
                <div>
                  유저 개개인에게 메세지를 보내야 할 때 사용합니다. (복수 선택 가능)
                </div>
                <div>
                  유저의 이름을 검색하고 추가를 눌러주세요.
                </div>
              </div>
            </v-list-item-title>
            <v-list-item-content>
              <UserInput v-model="user" />
              <v-btn color="#ffd600" light depressed @click="addUserList">
                추가
              </v-btn>
              <div v-for="(item, index) in req.userList" :key="index" class="mt-3">
                {{ index + 1 }}. {{ item.name }}
                <v-btn light class="ml-3 mb-1" rounded small color="#ffd600" @click="req.userList.splice(index, 1)" v-text="'삭제'" />
              </div>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <v-btn color="#9146ff" class="mb-3" dark @click="notification(req)">{{ id ? '수정' : '발송' }}하기</v-btn>
    </v-card-actions>
    <v-snackbar v-model="base.snackbar" timeout="2500">
      항목을 선택하거나 정확히 입력해주세요.
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="base.snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'

import GroupInput from '@/components/input/Group.vue'
import UserInput from '@/components/input/User.vue'
import AppLinkInput from '@/components/input/AppLink.vue'

export default defineComponent({
  props: { type: String, idOfType: String, notificationId: Number },

  components: {
    GroupInput,
    UserInput,
    AppLinkInput,
  },

  setup(props, { root }) {
    const msAndHsGrade = ['전체', '1학년', '2학년', '3학년']

    const state = reactive({
      uri: process.env.NODE_ENV,
      id: null,
      status: 'show',
      date: null,
      time: null,
      dateMenu: false,
      timeMenu: false,
      base: {
        snackbar: false,
        all: true,
        temp: null,
        first: null,
        second: null,
        third: null,
        fourth: null,
        ifList: {
          학력: {
            초등학교: [...msAndHsGrade, '4학년', '5학년', '6학년'],
            중학교: {
              전체: msAndHsGrade,
              일반: msAndHsGrade,
              국제: msAndHsGrade,
              예술: msAndHsGrade,
              체육: msAndHsGrade,
              대안: msAndHsGrade,
              기타: msAndHsGrade,
              NUL: msAndHsGrade,
            },
            고등학교: {
              전체: msAndHsGrade,
              일반: msAndHsGrade,
              자율: msAndHsGrade,
              특목: msAndHsGrade,
              특성화: msAndHsGrade,
              전문: msAndHsGrade,
              과학: msAndHsGrade,
              국제: msAndHsGrade,
              예술: msAndHsGrade,
              외국어: msAndHsGrade,
              체육: msAndHsGrade,
              대안: msAndHsGrade,
              기타: msAndHsGrade,
              NUL: msAndHsGrade,
            },
            N수: null,
            학부모: null,
            검정고시: null,
            멘토: null,
            기타: null,
          },
          질답횟수: {
            '0번': null,
            '1번': null,
            '2번': null,
            '3번 이상': null,
          },
          답변평균점수: null,
          최종접속일: {
            '0~24시간': null,
            '24~48시간': null,
            '48~72시간': null,
            '72시간 이후': null,
          },
          성별: {
            남성: null,
            여성: null,
            기타: null,
          },
        },
      },
      req: {
        acceptPush: null,
        message: {
          title: null,
          content: null,
        },
        groupList: [],
        userList: [],
      },
      isAcceptPush: false,
      checked: false,
      ifList: [],
      group: null,
      user: null,
      appLink: null,
    })

    const changeAll = () => {
      if (!state.base.all) return
      state.req.userList = []
      state.req.groupList = []
      state.ifList = []
    }

    const addIf = () => {
      if (!state.base.first) return (state.base.snackbar = true)
      else if (state.base.secondList && !state.base.second) return (state.base.snackbar = true)
      else if (state.base.thirdList && !state.base.third) return (state.base.snackbar = true)
      else if (state.base.fourthList && !state.base.fourth) return (state.base.snackbar = true)
      if (state.base.first === '답변평균점수') {
        state.ifList.push([state.base.first, state.base.temp ?? '평가 없음', state.base.third, state.base.fourth])
      } else if (state.base.second === '재수' || state.base.second === '반수') {
        if (!state.base.temp || state.base.temp < 0) return (state.base.snackbar = true)
        state.ifList.push([state.base.first, state.base.second, state.base.temp, state.base.fourth])
      } else state.ifList.push([state.base.first, state.base.second, state.base.third, state.base.fourth])
      state.base.all = false
    }

    const changeDepth = (number, first, second, third) => {
      state.base.fourth = null
      state.base.fourthList = null
      state.base.temp = null
      if (number === 2) {
        if (!Array.isArray(state.base.ifList[first][second])) state.base.fourthList = state.base.ifList[first][second][third]
      } else if (number === 1) {
        state.base.third = null
        if (!Array.isArray(state.base.ifList[first])) state.base.thirdList = state.base.ifList[first][second]
      } else if (number === 0) {
        state.base.third = null
        state.base.second = null
        state.base.thirdList = null
        if (!Array.isArray(state.base.ifList)) state.base.secondList = state.base.ifList[first]
      }
    }

    const reNotification = async (date) => {
      try {
        let type = 'reNotification'
        if (state.status === 'keep') type = 'cancelNotification'

        await root.$store.dispatch('notification/' + type, {
          reservationTime: state.status === 'show' ? null : state.status === 'pending' ? date : undefined,
          id: Number(state.id),
        })

        alert('수정 완료')
        root.$router.push('/notification')
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const notification = async (req) => {
      try {
        let date = null
        if (state.date && state.time) date = new Date(state.date + 'T' + state.time)
        else if (state.date) date = new Date(state.date + 'T00:00')

        if (state.id) return reNotification(date)

        if (state.checked === false) {
          if (state.uri === 'development') {
            state.appLink = { link: 'devhak://dev.hakhak.io/for-students/home' }
          } else if (state.uri === 'staging') {
            state.appLink = { link: 'stghak://staging.hakhak.io/for-students/home' }
          } else {
            state.appLink = { link: 'hakhak://www.hakhak.io/for-students/home' }
          }
        }

        const data = {
          ...req,
          acceptPush: state.isAcceptPush ? req.acceptPush : undefined,
          type: props.type,
          idOfType: props.idOfType ? Number(props.idOfType) : null,
          receivers: {},
          userList: req.userList.map((user) => Number(user.id)),
          groupList: req.groupList.map((group) => group.value),
          reservationTime: state.status === 'pending' ? date : undefined,
          appLink: state.appLink.link ?? undefined,
        }

        const res = data.receivers
        for (const index in state.ifList) {
          const e = state.ifList[index]
          if (e[0] === '학력') {
            if (!res.schoolList) res.schoolList = []

            const education = {
              초등학교: 'elementary',
              중학교: 'middle',
              고등학교: 'high',
              학부모: 'parents',
              검정고시: 'homeSchool',
              멘토: 'mentor',
              기타: 'other',
            }

            if (e[1] === 'N수') {
              res.schoolList.push({ education: 'nAgain' }, { education: 'halfAgain' })
            } else if (education[e[1]]) {
              res.schoolList.push({ education: education[e[1]] })
            }

            const lastSchoolList = res.schoolList[res.schoolList.length - 1]

            const kindList = {
              일반: ['general'],
              자율: ['autonomous'],
              특목: ['specialPurpose'],
              특성화: ['specialized'],
              전문: ['technical'],
              과학: ['science'],
              국제: ['international'],
              예술: ['art'],
              외국어: ['foreign'],
              체육: ['physical'],
              대안: ['alternative'],
              기타: ['etc'],
              NUL: ['empty', 'nul'],
            }

            if (e[2] && e[2].includes('학년')) {
              lastSchoolList.yearList = [Number(e[2][0])]
            } else if (kindList[e[2]]) {
              lastSchoolList.kindList = kindList[e[2]]
            }

            if (e[3] && e[3].includes('학년')) {
              lastSchoolList.yearList = [Number(e[3][0])]
            }
          } else if (e[0] === '질답횟수') {
            if (!res.questionCountList) res.questionCountList = []
            res.questionCountList.push(Number(e[1][0]))
          } else if (e[0] === '답변평균점수') {
            if (!res.likeAvgList) res.likeAvgList = []
            if (e[1] === '평가 없음') res.likeAvgList.push(0)
            else res.likeAvgList.push(Number(e[1]))
          } else if (e[0] === '최종접속일') {
            if (!res.afterBeforeTimeList) res.afterBeforeTimeList = []
            if (e[1] === '0~24시간') res.afterBeforeTimeList.push([0, 24])
            else if (e[1] === '24~48시간') res.afterBeforeTimeList.push([24, 48])
            else if (e[1] === '48~72시간') res.afterBeforeTimeList.push([48, 72])
            else if (e[1] === '72시간 이후') res.afterBeforeTimeList.push([72, 0])
          } else if (e[0] === '성별') {
            if (!res.sexList) res.sexList = []
            if (e[1] === '남성') res.sexList.push('male')
            else if (e[1] === '여성') res.sexList.push('female')
            else if (e[1] === '기타') res.sexList.push('none')
          }
        }

        const ifList = state.ifList.map((e) => {
          let str = '\n' + e[0]
          if (e[1]) str += ' - ' + e[1]
          if (e[2]) str += ' - ' + e[2]
          if (e[3]) str += ' - ' + e[3]
          return str
        })
        const groupList = state.req.groupList.map((group) => '\n그룹 - ' + group.name)
        const userList = state.req.userList.map((user) => '\n유저 - ' + user.name)

        if (!confirm(`아래 분류로 발송하겠습니까? ${state.base.all ? '\n전체' : [...ifList, ...groupList, ...userList]}`)) return

        await root.$store.dispatch('notification/notification', data)

        alert('발송 완료')
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const addUserList = () => {
      if (!state.user) return (state.base.snackbar = true)
      if (!state.req.userList.find((e) => e === state.user)) state.req.userList.push(state.user)
      state.base.all = false
      state.user = null
    }

    const addGroupList = () => {
      if (!state.group) return (state.base.snackbar = true)
      if (!state.req.groupList.find((e) => e === state.group)) state.req.groupList.push(state.group)
      state.base.all = false
      state.group = null
    }

    const getNotification = async () => {
      try {
        const notification = await root.$store.dispatch('notification/getOneNotification', {
          id: Number(state.id),
        })

        state.req.message.title = notification.title
        state.req.message.content = notification.content

        if (notification.reservationTime) {
          const date = new Date(notification.reservationTime)
          const Y = String(date.getFullYear())
          const M = String(date.getMonth() + 1).padStart(2, '0')
          const D = String(date.getDate()).padStart(2, '0')
          const hh = String(date.getHours()).padStart(2, '0')
          const mm = String(date.getMinutes()).padStart(2, '0')
          state.date = Y + '-' + M + '-' + D
          state.time = hh + ':' + mm
          state.status = 'pending'
        }
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    onBeforeMount(() => {
      state.id = props.notificationId || root.$route.params.pushid
      if (state.id) getNotification()
    })

    return {
      ...toRefs(state),
      changeAll,
      addIf,
      changeDepth,
      notification,
      addUserList,
      addGroupList,
    }
  },
})
</script>
