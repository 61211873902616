<template>
  <v-row>
    <v-col cols="3">
      <v-combobox
        v-model="appLinkName"
        :disabled="!checked"
        :items="appLinkList"
        append-icon=""
        item-text="name"
        outlined
        hide-details
        clearable
        dense
        placeholder="직접입력"
      />
    </v-col>
    <v-col>
      <v-combobox v-model="appLink" :disabled="!checked" append-icon="" item-text="link" outlined hide-details dense placeholder="앱링크 url을 넣어주세요" />
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent, reactive, watch, toRefs } from '@vue/composition-api'

export default defineComponent({
  props: { value: [Object, Array], checked: Boolean },

  setup(props, { emit }) {
    const state = reactive({
      uri: process.env.NODE_ENV,
      protocol: {
        prod: 'hakhak://www.hakhak.io',
        stg: 'stghak://staging.hakhak.io',
        dev: 'devhak://dev.hakhak.io',
      },
      appLinkList: [
        {
          name: '홈',
          link: '/for-students/home',
        },
        {
          name: '홈>배너',
          link: '/for-students/home?index=0',
        },
        {
          name: '홈>급식',
          link: '/for-students/home?index=1',
        },
        {
          name: '홈>이거 한 번 읽어봐',
          link: '/for-students/home?index=2',
        },
        {
          name: '홈>시간표',
          link: '/for-students/home?index=3',
        },
        {
          name: '홈>알림',
          link: '/for-students/home/notification',
        },
        {
          name: '홈>질문하기',
          link: '/for-students/home/write-question',
        },
        {
          name: '홈>받은 편지함',
          link: '/for-students/home/asked-questions',
        },
        {
          name: '홈>생선충전',
          link: '/for-students/home/fish-store',
        },
        {
          name: '홈>매일편지',
          link: '/for-students/home/daily-letter',
        },
        {
          name: '홈>학학이 멘토',
          link: '/for-students/home/mentors',
        },
        {
          name: '연구소',
          link: '/for-students/labs',
        },
        {
          name: '연구소>HOME',
          link: '/for-students/labs?tab=0',
        },
        {
          name: '연구소>칼럼',
          link: '/for-students/labs?tab=1',
        },
        {
          name: '연구소>레포트',
          link: '/for-students/labs?tab=2',
        },
        {
          name: '연구소>생선상점',
          link: '/for-students/labs/fish-store',
        },
        {
          name: '연구소>검색',
          link: '/for-students/labs/search',
        },
        {
          name: '내정보',
          link: '/for-students/others',
        },
        {
          name: '내정보>서비스 안내',
          link: '/for-students/others?index=0',
        },
        {
          name: '내정보>프로필 수정',
          link: '/for-students/others/profile',
        },
        {
          name: '내정보>생선 상점',
          link: '/for-students/others/fish-store',
        },
        {
          name: '내정보>받은 편지함',
          link: '/for-students/others/asked-questions',
        },
        {
          name: '내정보>북마크',
          link: '/for-students/others/bookmark',
        },
        {
          name: '내정보>구매한 레포트',
          link: '/for-students/others/bought-reports',
        },
        {
          name: '내정보>공지사항',
          link: '/for-students/others/notice',
        },
        {
          name: '내정보>자주 묻는 질문',
          link: '/for-students/others/faq',
        },
        {
          name: '내정보>고객센터',
          link: '/for-students/others/service-center',
        },
        {
          name: '내정보>버전 정보',
          link: '/for-students/others/version',
        },
        {
          name: '내정보>추천인 등록하기',
          link: '/for-students/others/register-as-a-recommender',
        },
        {
          name: '내정보>알림 설정',
          link: '/for-students/others/push-message',
        },
        {
          name: '내정보>로그아웃',
          link: '/for-students/others/logout',
        },
        {
          name: '내정보>회원탈퇴',
          link: '/for-students/others/withdraw',
        },
      ],
      appLinkName: {
        name: '홈',
      },
      appLink: {
        link: '/for-students/home',
      },
    })

    for (const i in state.appLinkList) {
      if (state.uri === 'development') {
        state.appLinkList[i].link = state.protocol.dev + state.appLinkList[i].link
      } else if (state.uri === 'staging') {
        state.appLinkList[i].link = state.protocol.stg + state.appLinkList[i].link
      } else {
        state.appLinkList[i].link = state.protocol.prod + state.appLinkList[i].link
      }
    }

    if (state.uri === 'development') {
      state.appLink.link = state.protocol.dev + state.appLink.link
    } else if (state.uri === 'staging') {
      state.appLink.link = state.protocol.stg + state.appLink.link
    } else {
      state.appLink.link = state.protocol.prod + state.appLink.link
    }

    watch(
      () => props.value,
      () => {
        state.appLink = props.value
      },
    )

    watch(
      () => state.appLink,
      () => {
        emit('input', state.appLink)
      },
    )

    watch(
      () => state.appLinkName,
      () => {
        state.appLink = state.appLinkName
      },
    )

    watch(
      () => props.checked,
      () => {
        if (props.checked == false) {
          state.appLinkName = []
          state.appLink = []

          state.appLinkName.name = '홈'

          if (state.uri === 'development') {
            state.appLink.link = state.protocol.dev + '/for-students/home'
          } else if (state.uri === 'staging') {
            state.appLink.link = state.protocol.stg + '/for-students/home'
          } else {
            state.appLink.link = state.protocol.prod + '/for-students/home'
          }
        }
      },
    )

    return {
      ...toRefs(state),
    }
  },
})
</script>
