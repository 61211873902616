<template>
  <v-combobox v-model="group" hide-details :items="listGroup" item-text="name" item-value="name" outlined dense placeholder="유저 그룹을 선택해주세요." />
</template>

<script>
import { defineComponent, reactive, watch, toRefs, onBeforeMount } from '@vue/composition-api'

export default defineComponent({
  props: { value: [String, Object] },

  setup(props, { root, emit }) {
    const state = reactive({
      listGroup: [],
      group: null,
    })

    const getListGroup = async () => {
      try {
        state.listGroup = await root.$store.dispatch('group/getListGroup', {})
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    onBeforeMount(() => {
      getListGroup()
    })

    watch(
      () => props.value,
      () => {
        state.group = props.value
      },
    )

    watch(
      () => state.group,
      () => {
        emit('input', state.group)
      },
    )

    return {
      ...toRefs(state),
      getListGroup,
    }
  },
})
</script>
